import * as React from "react";
import { Accreditation } from "@coinlist/frontend-ui-lib"
require("@coinlist/frontend-ui-lib/dist/style.css");

export default function AccreditationFlow(props) {
    return (
        <Accreditation 
            initialStatus={props.initialStatus} 
            entityId={props.entityId} 
            locale={props.locale} 
            accessToken={props.accessToken} 
            email={props.email}
        />
    );
}
