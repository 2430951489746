const aaveLogo = require("../../../assets/images/market/icons/currencies/aave.svg");
const algoLogo = require("../../../assets/images/market/icons/currencies/algo.svg");
const alignLogo = require("../../../assets/images/market/icons/currencies/align.svg");
const axlLogo = require("../../../assets/images/market/icons/currencies/axl.svg");
const bldLogo = require("../../../assets/images/market/icons/currencies/bld.svg");
const btcLogo = require("../../../assets/images/market/icons/currencies/btc.svg");
const casperLogo = require("../../../assets/images/market/icons/currencies/cspr.svg");
const celoLogo = require("../../../assets/images/market/icons/currencies/celo.svg");
const cfgLogo = require("../../../assets/images/market/icons/currencies/cfg.svg");
const eFilLogo = require("../../../assets/images/market/icons/currencies/efil.svg");
const ethLogo = require("../../../assets/images/market/icons/currencies/eth.svg");
const ethfiLogo = require("../../../assets/images/market/icons/currencies/ethfi.svg");
const filLogo = require("../../../assets/images/market/icons/currencies/fil.svg");
const flowLogo = require("../../../assets/images/market/icons/currencies/flow.svg");
const imxLogo = require("../../../assets/images/market/icons/currencies/imx.svg");
const kingLogo = require("../../../assets/images/market/icons/currencies/king.svg");
const lbtcLogo = require("../../../assets/images/market/icons/currencies/lbtc.svg");
const minaLogo = require("../../../assets/images/market/icons/currencies/mina.svg");
const nuLogo = require("../../../assets/images/market/icons/currencies/nu.svg");
const roseLogo = require("../../../assets/images/market/icons/currencies/rose.svg");
const stxLogo = require("../../../assets/images/market/icons/currencies/stx.svg");
const suiLogo = require("../../../assets/images/market/icons/currencies/sui.svg");
const tLogo = require("../../../assets/images/market/icons/currencies/t.svg");
const tbtcLogo = require("../../../assets/images/market/icons/currencies/tbtc.svg");
const usdLogo = require("../../../assets/images/market/icons/currencies/usd.svg");
const usdcLogo = require("../../../assets/images/market/icons/currencies/usdc.svg");
const usdtLogo = require("../../../assets/images/market/icons/currencies/usdt.svg");
const vegaLogo = require("../../../assets/images/market/icons/currencies/vega.svg");
const waxlLogo = require("../../../assets/images/market/icons/currencies/waxl.svg");
const wbtcLogo = require("../../../assets/images/market/icons/currencies/wbtc.svg");
const wcfgLogo = require("../../../assets/images/market/icons/currencies/wcfg.svg");
const weethLogo = require("../../../assets/images/market/icons/currencies/weeth.svg");
const wstethLogo = require("../../../assets/images/market/icons/currencies/wsteth.svg");
const xtzLogo = require("../../../assets/images/market/icons/currencies/xtz.svg");

export const LOGO_MAP = {
  aave: aaveLogo,
  algo: algoLogo,
  align: alignLogo,
  axl: axlLogo,
  bld: bldLogo,
  btc: btcLogo,
  celo: celoLogo,
  cfg: cfgLogo,
  cspr: casperLogo,
  efil: eFilLogo,
  eth: ethLogo,
  ethfi: ethfiLogo,
  fil: filLogo,
  flow: flowLogo,
  imx: imxLogo,
  king: kingLogo,
  lbtc: lbtcLogo,
  mina: minaLogo,
  nu: nuLogo,
  rose: roseLogo,
  stx: stxLogo,
  sui: suiLogo,
  t: tLogo,
  tbtc: tbtcLogo,
  test_btc: btcLogo,
  teth: btcLogo,
  usd: usdLogo,
  usdc: usdcLogo,
  usdt: usdtLogo,
  vega: vegaLogo,
  waxl: waxlLogo,
  wbtc: wbtcLogo,
  wcfg: wcfgLogo,
  weeth: weethLogo,
  wsteth: wstethLogo,
  xtz: xtzLogo,
};
